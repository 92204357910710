import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";

const demoulantImg = "../images/produits/gelcoat1.jpg";
const gelcoatImg = "../images/produits/gelcoat3.jpg";
const resineImg = "../images/produits/resine3.jpg";
const colleImg = "../images/produits/gelcoat2.jpg";
const ameImg = "../images/produits/ame.jpg";
const solvantImg = "../images/produits/resine2.jpg";
const moulageImg = "../images/produits/gelcoat4.jpg";
const outillagesImg = "../images/produits/gelcoat1.jpg";
const renfortImg = "../images/produits/gelcoat3.jpg";
const videImg = "../images/produits/resine3.jpg";

const products = [
  {
    id: 0,
    title: "Démoulant",
    description:
      "Facilitez le démoulage de vos produits composites grâce à notre gamme de démoulants. Que vous préfériez la cire en pâte ou liquide, ou que vous ayez besoin de solutions semi-permanentes pour un processus plus avancé, nos démoulants vous aident à obtenir des surfaces impeccables et à prolonger la durée de vie de vos moules.",
    children: [
      "Cire en pate",
      "Cire liquide",
      "Semi-permanents (Nettoyant, bouche pore, démoulant)",
    ],
  },
  {
    id: 1,
    title: "GelCoat",
    description:
      "Protégez vos produits composites des intempéries et des rayons UV avec notre gamme de GelCoat de qualité supérieure. Des options adaptées à vos besoins spécifiques, du GelCoat pour moules pour une surface durable de vos moules, aux différents types de GelCoat (ISO NPG, ISO) et au Top Coat pour une finition parfaite. Pour les applications avancées, notre GelCoat à base d'époxy offre une résistance exceptionnelle.",
    children: ["Moule", "ISO NPG", "ISO", "Top Coat", "Epoxy"],
  },
  {
    id: 2,
    title: "Résine",
    description:
      "Choisissez parmi notre gamme de résines pour répondre aux besoins spécifiques de votre projet. Que vous recherchiez une résine orthophtalique, isophtalique, vinylester ou époxy, nous avons la formulation qui convient. Des résines de qualité, adaptées à diverses méthodes de fabrication, telles que le moulage ou l'infusion. Faites confiance à Carthage Composites Distribution pour des résines fiables et performantes.",
    children: [
      "Résine Orthophtalique",
      "Résine Isophtalique",
      "Résine Vinylester",
      "Résine Infusion",
      "Résine Epoxy",
    ],
  },
  {
    id: 3,
    title: "Colle",
    description:
      "Nos colles sont conçues pour répondre à des exigences spécifiques. Que vous ayez besoin d'une colle renforcée pour des charges importantes, d'une version allégée pour minimiser le poids, ou d'une colle structurale résistante, notre gamme de colles Carthage Composites Distribution est là pour répondre à vos besoins. Pour des applications nécessitant une résistance chimique élevée, notre colle à base de Vinylester offre une solution fiable.",
    children: [
      "Colle Armée",
      "Allégée",
      "Strucutrelle / Vinylester",
      "Mastic anti bulles",
    ],
  },
  {
    id: 4,
    title: "Ame",
    description:
      "L'âme de votre composite est essentielle pour la structure et la légèreté de vos produits. Notre gamme d'âmes inclut des options de qualité, du PVC au PET en passant par le polyuréthane (PU) et le nid d'abeille, chaque matériau sélectionné pour ses propriétés spécifiques. Nous proposons également le balsa, un choix traditionnel apprécié pour sa légèreté et sa résistance.",
    children: [
      "Mousse PVC",
      "Mousse PU",
      "Mousse PET",
      "Nid d'abeille",
      "Balsa",
    ],
  },
  {
    id: 5,
    title: "Solvant",
    description:
      "Les solvants jouent un rôle clé dans le traitement de vos composites. Notre sélection de solvants de haute qualité comprend des options telles que le styrène monomère, le chlorure de méthylène, l'acétate de méthyl, et d'autres diluants adaptés à différents besoins. Nos solvants sont formulés pour garantir une compatibilité optimale avec nos autres produits et pour répondre aux exigences de sécurité.",
    children: [
      "Styrène Monomère",
      "Acetate de Methyl",
      "Acetate d'éthyle",
      "Diluant",
    ],
  },
  {
    id: 6,
    title: "Moulage",
    description:
      "Obtenez une précision exceptionnelle pour vos moules avec notre gamme de produits de moulage. Que vous choisissiez le latex ou le silicone, nos matériaux de moulage de haute qualité vous offrent des détails nets et une durabilité. Ils sont idéaux pour une large gamme d'applications, de la production de pièces complexes à la création de moules pour des pièces d'art.",
    children: ["Latex", "Silicone", "Résine pour moule"],
  },
  {
    id: 7,
    title: "Outillages",
    description:
      "Nos outillages sont conçus pour vous offrir une application précise et efficace de vos matériaux composites. Que ce soit pour la découpe, le ponçage ou d'autres étapes du processus, notre gamme d'outillages Carthage Composites Distribution vous permet d'obtenir des résultats de qualité professionnelle. Des outils de pointe pour un travail exceptionnel.",
    children: [
      "Debulleurs",
      "Cartouches et montures",
      "Doseurs",
      "Rouleaus onducteur",
      "Cale de démoulage",
      "Peau de mouton",
    ],
  },
  {
    id: 8,
    title: "Fibre de verre et Renfort",
    description:
      "Renforcez vos composites avec notre gamme de fibres de verre de première qualité. Des mats de verre pour la résistance mécanique aux tissus de verre pour une distribution homogène des charges, en passant par les composites hybrides tels que le Combo Mat, le fil roving pour des applications exigeantes, les multiaxiaux et biaxiaux pour une résistance optimale, et le voile de surface C pour une finition de qualité supérieure.",
    children: [
      "Mats de verre",
      "Tissus de verre",
      "Combo Mat",
      "Fil roving",
      "Multiaxiaux, biaxiaux et UD",
      "Voile de verre",
    ],
  },
  {
    id: 9,
    title: "Produits de mise sous vide",
    description:
      "Pour garantir la qualité de vos composites, une mise sous vide efficace est essentielle. Notre gamme de produits de mise sous vide comprend des complexes de qualité, des filets de drainage pour éliminer l'excès de résine, et des joints vides pour assurer une adhérence fiable. Ces produits sont conçus pour faciliter le processus de fabrication et améliorer la qualité de vos produits finis.",
    children: [
      "Complexe",
      "Joint vide",
      "Film perforé",
      "Tissus d'arrachage",
      "Colle Aérosol",
    ],
  },
];

const Produits = () => {
  return (
    <Layout>
      <Seo title="Produits" />
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-center">
              <h2 className="title">Nos Produits</h2>
              <h5 className="description">
                <b>
                  Dans notre quête constante de vous offrir le meilleur en
                  matière de produits composites, Carthage Composites
                  Distribution met en avant sa sélection méticuleuse d'articles
                  indispensables. Notre compréhension approfondie de
                  l'importance du choix des matériaux adaptés à chaque projet se
                  reflète dans notre gamme de solutions diversifiées,
                  spécialement conçues pour répondre à vos exigences uniques.
                </b>
              </h5>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-5">
        <div className="container pb-5">
          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={demoulantImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[0].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[0].description}</b>
              </h5>
              <ul>
                {products[0].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={gelcoatImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[2].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[2].description}</b>
              </h5>
              <ul>
                {products[2].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={resineImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[3].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[3].description}</b>
              </h5>
              <ul>
                {products[3].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={colleImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[4].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[4].description}</b>
              </h5>
              <ul>
                {products[4].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={ameImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[5].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[5].description}</b>
              </h5>
              <ul>
                {products[5].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={solvantImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[6].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[6].description}</b>
              </h5>
              <ul>
                {products[6].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={moulageImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[7].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[7].description}</b>
              </h5>
              <ul>
                {products[7].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={outillagesImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[8].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[8].description}</b>
              </h5>
              <ul>
                {products[8].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={renfortImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[9].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[9].description}</b>
              </h5>
              <ul>
                {products[9].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <hr className="custom-divider" />

          <div className="row py-5">
            <div className="col-md-3">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={videImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-9">
              <h3 className="text-danger">
                <b>{products[1].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{products[1].description}</b>
              </h5>
              <ul>
                {products[1].children.map((item) => (
                  <li>
                    <h5 className="description">
                      <b>{item}</b>
                    </h5>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Produits;
